import Slider from "react-slick";
import React, { Component } from "react";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";



const TestimonialArea = () => {

    const {t} = useTranslation();

    const settings = {
            
        className: "center",
        dots: true,
        centerMode: true,
        infinite: true,
        centerPadding: "0px",
        slidesToShow: 2,
        initialSlide: 0,
        speed: 1000,
        autoplaySpeed: 4000,
        cssEase: "linear",
        autoplay: true,
      //   rows: 2,
      //   slidesPerRow: 0,
      //   nextArrow: <SampleNextArrow />,
      //   prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

    return(
        <>
        {/* <!-- Testimonial Area --> */}
    <div id="tm-area-testimonial" className="tm-testimonial-area tm-section tm-padding-section bg-white">
        <div className="tm-testimonial-bgshape">
            <img src="assets/images/testimonial-bg-shape.png" alt="appmyil bg shape"/>
        </div>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="tm-sectiontitle text-center">
                        <h2>{t('section_Testimonial_title')}</h2>
                        <span className="tm-sectiontitle-divider"><i className="zmdi zmdi-fullscreen"></i></span>
                    </div>
                </div>
            </div>  
            <div className="row justify-content-center">
                <div className="col-lg-8 col-md-10">
                  
                    <div className="tm-testimonial-authors">
                    <Slider {...settings}>
                        <div className="tm-testimonial-author">
                            <div className="tm-testimonial-authorinner">
                                <img src="assets/images/author-image-2.jpg" alt="appmyil author image"/>
                            </div>
                            <div className="tm-testimonial-content">
                                <p>Un bon soutien = un bonne réussite et la solution c'est AmphiMill. </p>
                                <i className="zmdi zmdi-quote"></i>
                                <h4>Dradone Ymele Takougang</h4>
                                {/* <h6>Visual Designer</h6> */}
                            </div>
                        </div>
                        <div className="tm-testimonial-author">
                            <div className="tm-testimonial-authorinner">
                                <img src="assets/images/author-image-2.jpg" alt="appmyil author image"/>
                            </div>
                            <div className="tm-testimonial-content">
                                <p>Très bonne initiative</p>
                                <i className="zmdi zmdi-quote"></i>
                                <h4>Emmanuel Ngweha</h4>
                                {/* <h6>Frontend Developer</h6> */}
                            </div>
                        </div>
                        <div className="tm-testimonial-author">
                            <div className="tm-testimonial-authorinner">
                                <img src="assets/images/author-image-2.jpg" alt="appmyil author image"/>
                            </div>
                            <div className="tm-testimonial-content">
                                <p>Je suis reconnaissante envers AmphiMill pour m'avoir simplifié la recherche de stages. Grâce à leur réseau de partenaires, j'ai pu trouver un stage dans une entreprise de renom en lien avec ma filière d'études. Cela a vraiment boosté mon CV et m'a ouvert de nouvelles opportunités professionnelles. Je recommande vivement cette
                                     application à tous les étudiants qui cherchent à se démarquer dans leur domaine.</p>
                                <i className="zmdi zmdi-quote"></i>
                                <h4>Alex Nguyen</h4>
                                {/* <h6>UI/UX Designer</h6> */}
                            </div>
                        </div>
                        <div className="tm-testimonial-author">
                            <div className="tm-testimonial-authorinner">
                                <img src="assets/images/author-image-2.jpg" alt="appmyil author image"/>
                            </div>
                            <div className="tm-testimonial-content">
                                <p>AmphiMill est un incontournable pour tous les étudiants. L'accès à la banque d'épreuves m'a permis de m'entraîner efficacement pour mes examens, et j'ai obtenu de meilleures notes grâce à cela. Cette application est 
                                    une véritable mine d'or pour les étudiants soucieux de réussir leurs études.</p>
                                <i className="zmdi zmdi-quote"></i>
                                <h4>Stephani Megni</h4>
                                {/* <h6>Web Developer</h6> */}
                            </div>
                        </div>
                        </Slider>
                    </div>

                    {/* <div className="tm-testimonial-contents tm-slider-arrow">
                        <div className="tm-testimonial-content">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiuod incididunt
                                ametfh consectetur dolore magna aliqua. Ut enim admim veniam dolor sit amet
                                magnaelit ate consectetur. Progravida nibh vel velit auctor alinean
                                sollicitudin. </p>
                            <i className="zmdi zmdi-quote"></i>
                            <h4>Albert Barnes</h4>
                            <h6>Visual Designer</h6>
                        </div>
                        <div className="tm-testimonial-content">
                            <p>Atque dicta voluptas et eos sint accusantium aut laboriosam. Omnis voluptatum ea
                                aut tempora recusandae qui facere. Consectetur hic tempore deleniti ea sit.
                                repellendus reprehenderit est veritatis quam laudantium. consequatur qui nihil
                                eos quibusdam</p>
                            <i className="zmdi zmdi-quote"></i>
                            <h4>Frankie Anderson</h4>
                            <h6>Frontend Developer</h6>
                        </div>
                        <div className="tm-testimonial-content">
                            <p>Architecto aut error explicabo. Voluptatem eaque sequi qui beatae velit vero aut
                                autem. Et adipisci perferendis corporis et similique vitae consequatur. Aut
                                eveniet quam. Et ut placeat consequatur qui nihil eos quibusdam. Impedit
                                blanditiis delectus.</p>
                            <i className="zmdi zmdi-quote"></i>
                            <h4>Tyreek Hoppe</h4>
                            <h6>UI/UX Designer</h6>
                        </div>
                        <div className="tm-testimonial-content">
                            <p>Eos asperiores minima non eaque. Quisquam voluptas impedit nihil. Repellat
                                dolorum et repellat earum voluptates ut voluptatibus quo. Autem eius non
                                quibusdam dolore. Neque doloribus repellendus reprehenderit est veritatis quam
                                laudantium.</p>
                            <i className="zmdi zmdi-quote"></i>
                            <h4>Darrick Kuvalis</h4>
                            <h6>Web Developer</h6>
                        </div>
                    </div> */}
                    
                </div>
            </div>
        </div>
    </div>
    {/* <!--// Testimonial Area --> */}
</>
    )
}



export default TestimonialArea