import Header from '../components/Header';
import SingleFeature from '../components/Single-feature';
import ABoutUs from '../components/About-Us';
import ServiceArea from '../components/Service-area';
import FunfactArea from '../components/Funfact-area';
import ScreenshotArea from '../components/Screenshot-area';
import TeamMember from '../components/Team-member';
import TestimonialArea from '../components/Testimonial-area';
import FaqArea from '../components/Faq-area';
import DownloadArea from '../components/Download-area';
import SubscribeArea from '../components/Subscribe-area';
import OurLastBlog from '../components/OurLast-blog';
import ContactArea from '../components/Contact-area';
import FacebookPosts from '../components/Facebook-Blog';
import Footer from '../components/Footer';


function HomePage () {

    return (
        <div id="wrapper" className="wrapper">

        {/* <NavBar/> */}

        <Header/>

        {/* <!-- Page Content --> */}
        <main className="page-content">

          <SingleFeature/>

          <ABoutUs/>

          <ServiceArea/>

          <FunfactArea/>

          <ScreenshotArea/>

          <TeamMember/>

          <TestimonialArea/>

          {/* <PricingArea/>  */}

          <FaqArea/>

          <DownloadArea/>

          <SubscribeArea/>

          {/* <OurLastBlog/> */}
          <FacebookPosts/>

          <ContactArea/>
 

        </main>

        <Footer/>
        
      </div>
    )
}

export default HomePage;