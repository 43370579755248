import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";


function Post ({key, message, createdTime, imageSrc, link}) {
    const {t} = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);
    const maxLength = 400; // Nombre maximum de caractères avant de tronquer

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    
    // console.log("tttttttttttt", link);
    
      return(
        <div key={key} className="col-lg-4 col-md-6 col-12">
                          <div className="tm-blog">
                              <div className="tm-blog-topside">
                                  <div className="tm-blog-image">
                                  <img src={imageSrc} alt="appmyil blog image"/>
                                      
                                  </div>
                                  <span className="tm-blog-date">{new Date(createdTime).toLocaleString()}</span>
                              </div>
                              <div className="tm-blog-content">
                                  <h4><a href="blog-details.html"> </a></h4>
                                  <p>
                                        {isExpanded ? message : `${message.substring(0, maxLength)}${message.length > maxLength ? '...' : ''}`}
                                        {message.length > maxLength && (
                                        <span onClick={toggleExpand} style={{ color: '#800080', cursor: 'pointer' }}>
                                            {isExpanded ? ' Voir moins' : ' Voir plus'}
                                        </span>
                                        )}
                                    </p>
                                  <a href={link} className="tm-button tm-button-sm tm-button-dark" target="_blank"><span>{t('section_Blog_button')}</span></a>
                              </div>
                          </div>
                      </div>
    )
}

export default Post;