import React from 'react';
import { useTranslation } from "react-i18next";
import i18next from "i18next";



function SubscribeArea () {


    const {t} = useTranslation();


    return (
        <>
                 {/* <!-- Subscribe Area --> */}
            <div id="tm-area-subscribe" className="tm-subscribe-area tm-section tm-padding-section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="tm-subscribe-image text-center">
                                <img src="assets/images/subscribe-area-image.png" alt="subscribe image" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="tm-subscribe-content text-center">
                                <h2>{t('section_Newletter_title')}</h2>
                                <p>{t('section_Newletter_sub')}</p>
                                <form id="tm-mailchimp-form" className="tm-subscribe-form text-center">
                                    <input id="mc-email" type="text" placeholder={t('section_Newletter_inputPlaceholder')} required="required" />
                                    <button id="mc-submit" type="submit" className="tm-button"><span>{t('section_Newletter_button')}</span></button>
                                </form>
                                {/* <!-- Mailchimp Alerts --> */}
                                <div className="tm-mailchimp-alerts">
                                    <div className="tm-mailchimp-submitting"></div>
                                    <div className="mailchimp-success"></div>
                                    <div className="tm-mailchimp-error"></div>
                                </div>
                                {/* <!--// Mailchimp Alerts --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--// Subscribe Area --> */}
        </>)}

export default SubscribeArea