import React from 'react';
import { useTranslation } from "react-i18next";
import i18next from "i18next";


function ServiceArea () {

    const {t} = useTranslation();

    return (
        <>
                 {/* <!-- Services Area --> */}
            <div id="tm-area-services" className="tm-services-area tm-section tm-padding-section bg-white">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="tm-sectiontitle text-center">
                                <h2>{t('section_other_features')}</h2>
                                <span className="tm-sectiontitle-divider"><i className="zmdi zmdi-fullscreen"></i></span>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="row mt-40-reverse">

                                {/* <!-- Single Features --> */}
                                <div className="col-lg-12 col-md-6 col-12 mt-40">
                                    <div className="tm-service">
                                        <span className="tm-service-icon">
                                            <i className="flaticon-friendship"></i>
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                                width="70px" height="72px">
                                                {/* <path fill-rule="evenodd" d="M52.556,54.307 C64.348,37.302 73.747,12.982 68.512,4.877 C60.795,-7.064 13.848,4.659 2.850,20.520 C2.466,21.074 2.124,21.636 1.831,22.199 C-6.854,38.889 17.104,75.968 33.774,71.638 C39.513,70.148 46.364,63.237 52.556,54.307 " /> */}
                                            </svg>
                                        </span>
                                        <div className="tm-service-content">
                                            <h4>{t('section_other_features1_title')}</h4>
                                            <p>{t('section_other_features1_desc')}</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--// Single Features -->

                                <!-- Single Features --> */}
                                <div className="col-lg-12 col-md-6 col-12 mt-40">
                                    <div className="tm-service">
                                        <span className="tm-service-icon">
                                            <i className="flaticon-support"></i>
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                                width="70px" height="72px">
                                                {/* <path fill-rule="evenodd" d="M52.556,54.307 C64.348,37.302 73.747,12.982 68.512,4.877 C60.795,-7.064 13.848,4.659 2.850,20.520 C2.466,21.074 2.124,21.636 1.831,22.199 C-6.854,38.889 17.104,75.968 33.774,71.638 C39.513,70.148 46.364,63.237 52.556,54.307 " /> */}
                                            </svg>
                                        </span>
                                        <div className="tm-service-content">
                                            <h4>{t('section_other_features2_title')}</h4>
                                            <p>{t('section_other_features2_desc')}</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--// Single Features -->

                                <!-- Single Features --> */}
                                <div className="col-lg-12 col-md-6 col-12 mt-40">
                                    <div className="tm-service">
                                        <span className="tm-service-icon">
                                            <i className="flaticon-team"></i>
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                                width="70px" height="72px">
                                                {/* <path fill-rule="evenodd" d="M52.556,54.307 C64.348,37.302 73.747,12.982 68.512,4.877 C60.795,-7.064 13.848,4.659 2.850,20.520 C2.466,21.074 2.124,21.636 1.831,22.199 C-6.854,38.889 17.104,75.968 33.774,71.638 C39.513,70.148 46.364,63.237 52.556,54.307 " /> */}
                                            </svg>
                                        </span>
                                        <div className="tm-service-content">
                                            <h4>{t('section_other_features3_title')}</h4>
                                            <p>{t('section_other_features3_desc')}</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--// Single Features -->

                                <!-- Single Features --> */}
                                {/* <div className="col-lg-12 col-md-6 col-12 mt-40">
                                    <div className="tm-service">
                                        <span className="tm-service-icon">
                                            <i className="flaticon-hourglass"></i>
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink"
                                                width="70px" height="72px">
                                                <path fill-rule="evenodd" d="M52.556,54.307 C64.348,37.302 73.747,12.982 68.512,4.877 C60.795,-7.064 13.848,4.659 2.850,20.520 C2.466,21.074 2.124,21.636 1.831,22.199 C-6.854,38.889 17.104,75.968 33.774,71.638 C39.513,70.148 46.364,63.237 52.556,54.307 " />
                                            </svg>
                                        </span>
                                        <div className="tm-service-content">
                                            <h4>Perfect Showcase</h4>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc mauris
                                                arcu, lobortis id interdum vitae, interdum eget elit. Cura
                                                bitur quis urna nulla. Suspendisse potenti.</p>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <!--// Single Features --> */}

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="tm-service-image">
                                <div className="tm-service-image-1 is-visible">
                                    <img src="assets/images/service-image-1.png" alt="appmyil mobile"/>
                                </div>
                                <div className="tm-service-image-2">
                                    <img src="assets/images/service-image-2.png" alt="appmyil mobile"/>
                                </div>
                                <div className="tm-service-mobileshowanim">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--// Services Area --> */}
        </>
    )}

    export default ServiceArea