import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import i18n from "i18next";
import {initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import 'flag-icon-css/css/flag-icons.min.css';
import { BrowserRouter } from "react-router-dom";

import './index.css';
import App from './App';


i18n
  .use(initReactI18next)
  //.use(LanguageDetector)
  .use(HttpApi)  // passes i18n down to react-i18next
  .init({
    supportedLngs: ['fr', 'en'],
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)

    
    // if you're using a language detector, do not define the lng option
    fallbackLng: "fr",
    detection: {
      //order: ['queryString','cookie'],
      order: ['path','cookie', 'htmlTag', , 'localStorage', , 'subdomain'],
      caches: ['cookie']
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
    react: { useSuspense: false}
  });

  const detectedLanguage = i18n.language ;
console.log('Langue du dispositif:', detectedLanguage);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
     <App />
    </BrowserRouter>,
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
