import React from 'react';
import { useTranslation } from "react-i18next";
import i18next from "i18next";



function DownloadArea () {

    const {t} = useTranslation();


    return (
        <>
                {/* <!-- Downlaod Area --> */}
            <div id="tm-area-download" className="tm-downlaod-area tm-section tm-padding-section bg-gradient">
                <div className="tm-download-bgshape">
                    <img src="assets/images/download-bgshape.png" alt="bg shape"/>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="tm-download-content">
                                <img src="assets/images/logo-white.png" alt="appmyil"/>
                                <h4>{t('section_Download_title')}</h4>
                                <h3>{t('section_Download_sub')}</h3>
                                <div className="tm-buttongroup">
                                    <a href="https://play.google.com/store/apps/details?id=com.amphimill.app" className="tm-button tm-button-lg tm-button-white"><i className="zmdi zmdi-android"></i><span>Google
                                            Play</span></a>
                                    <a href="https://apps.apple.com/fr/app/amphi-mill/id6466167435" className="tm-button tm-button-lg tm-button-white tm-button-transparent"><i
                                            className="zmdi zmdi-apple"></i><span>IOS App Store</span></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="tm-download-graph text-center">
                                <h5>{t('section_Download_stat')}</h5>
                                <canvas id="downloadgraph" height="185"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--// Downlaod Area --> */}
        </>
    )}

export default DownloadArea