import React from 'react';
import { useTranslation } from "react-i18next";
import i18next from "i18next";



function Footer () {

    const {t} = useTranslation();

    function myFunction() {
        var x = document.getElementById("myLinks");
        if (x.style.display === "block") {
          x.style.display = "none";
        } else {
          x.style.display = "block";
        }
    }

    return (
        <>
                {/* <!-- Footer Area --> */}
        <div className="tm-footer bg-gradient">
            <div className="tm-footer-bgshape">
                <img src="assets/images/footer-bgshape.png" alt="footer background shape"/>
            </div>
            <div className="container">
                <div className="tm-footer-toparea tm-padding-section">
                    <div className="row widgets tm-footer-widgets">
                        <div className="col-xl-5 col-lg-4 col-md-6 col-12">
                            <div className="single-widget widget-info">
                                <a href="index.html" className="widget-info-logo">
                                    <img src="assets/images/logo-footer.png" alt="appmyil logo"/>
                                </a>
                                <p>{t('section_Footer_desc')}</p>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6 col-12">
                            <div className="single-widget widget-quicklinks">
                                <h5 className="widget-title">{t('menu_feature')}</h5>
                                <div>
                                    <ul>
                                        <li><a href="#tm-area-about" onClick={()=>myFunction()}>{t('menu_about')}</a></li>
                                        <li><a href="#tm-area-blog">{t('menu_blog')}</a></li>
                                        <li><a href="#tm-area-faq">FAQs</a></li>
                                        <li><a href="#tm-area-contact">{t('menu_conact')}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6 col-12">
                            <div className="single-widget widget-quicklinks">
                                <h5 className="widget-title">Support</h5>
                                <ul>
                                    <li><a href="#tm-area-contact">Help center</a></li>
                                    <li><a href="#tm-area-faq">How it work</a></li>
                                    <li><a href="#">Terms of use</a></li>
                                    <li><a href="#tm-area-contact">Forum</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                            <div className="single-widget widget-recentpost">
                                <h5 className="widget-title">Recent Posts</h5>
                                <ul>
                                    <li>
                                        <div className="widget-recentpost-content">
                                            <h6><a href="https://www.facebook.com/profile.php?id=100092409129553">Fundamental analysis
                                                    services</a></h6>
                                            <span>August 01, 2019</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="widget-recentpost-content">
                                            <h6><a href="https://www.facebook.com/profile.php?id=100092409129553">Steps to a successful
                                                    Business</a></h6>
                                            <span>October 07, 2023</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tm-footer-bottomarea">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <p className="tm-footer-copyright">Copyright 2023 <a href="#">amphimill</a>. Designed by <a href="#">AmphiMill</a></p>
                        </div>
                        <div className="col-md-5">
                            <ul className="tm-footer-social">
                                <li>
                                    <a href="https://www.facebook.com/profile.php?id=100092409129553">
                                        <i className="zmdi zmdi-facebook"></i>
                                        <i className="zmdi zmdi-facebook"></i>
                                    </a>
                                </li>
                                {/* <li>
                                    <a href="#">
                                        <i className="zmdi zmdi-dribbble"></i>
                                        <i className="zmdi zmdi-dribbble"></i>
                                    </a>
                                </li> */}
                                <li>
                                    <a href="https://instagram.com/amphimill?igshid=MzRlODBiNWFlZA==">
                                        <i className="zmdi zmdi-instagram"></i>
                                        <i className="zmdi zmdi-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/watch?v=fFZHhZazXK4">
                                        <i className="zmdi zmdi-youtube"></i>
                                        <i className="zmdi zmdi-youtube"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/amphimill/">
                                        <i className="zmdi zmdi-linkedin"></i>
                                        <i className="zmdi zmdi-linkedin"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!--// Footer Area --> */}
        </>
    )
}

export default Footer