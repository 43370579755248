import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import axios from 'axios';
import Post from '../widgets/Post';

function FacebookPosts () {
  const [posts, setPosts] = useState([]);
  const pageId = '101797859582172';
  const accessToken = 'EAAMiujK3g9EBO9jsAe8VdwKyZAIKZBZBYLBUXOA1ZCZBVNzRyypB9wWDcXNG4LZCcc6JiFvjCmHkYWWgW21MZCsepTys8ye1hUIPZCYx58FdMwMkrlMnwy9Fbe2N5HYfs9thZA1MnXs4bCTCvnIuc6ivRWuP4RkkgPMZCgAcO56fMyncm8mhY6KPhsPPHXfwpUBOopKwpQJC8nPxaKZCzsZD';
  const {t} = useTranslation();

    const settings = {
            
        className: "center",
        dots: true,
        centerMode: true,
        infinite: true,
        centerPadding: "0px",
        slidesToShow: 3,
        speed: 500,
        initialSlide: 0,
        autoplaySpeed: 2000,
        cssEase: "linear",
        autoplay: true,
      //   nextArrow: <SampleNextArrow />,
      //   prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

  useEffect(() => {

   
    const fetchPosts = async () => {
      try {
        const response = await axios.get(
          `https://graph.facebook.com/${pageId}/posts?fields=message,created_time,attachments{media},permalink_url&access_token=${accessToken}`
        );
        setPosts(response.data.data);
       
      } catch (error) {
        console.error('Error fetching posts from Facebook:', error);
      }
    };

    fetchPosts();
    
  }, [accessToken, pageId ]);
  
  return (
    <div id="tm-area-blog" className="tm-blog-area tm-section tm-padding-section bg-grey">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="tm-sectiontitle text-center">
                        <h2>{t('section_Blog_title')}</h2>
                        <span className="tm-sectiontitle-divider"><i className="zmdi zmdi-fullscreen"></i></span>
                    </div>
                </div>
            </div>
            <div className=" tm-blog-slider tm-slider-arrow">
                
          {/* { posts.map((post) => (
             <Post
             key={post.id}
             message={post.message}
             createdTime={post.created_time}
            // imageSrc={post.attachments?.data[0]?.media?.image?.src}
            />
          ))} */}
                <Slider {...settings}>
                           
                { posts.map((post) => (
             <Post
             key={post.id}
             message={post.message}
             createdTime={post.created_time}
             imageSrc={post.attachments?.data[0]?.media?.image?.src}
             link={post.permalink_url}
            />
          ))}


                </Slider>

            </div>
        </div>
    </div>
    // <div>
    //   <h2>Facebook Blog Posts</h2>
    //   <ul>
    //     {posts.map((post) => (
    //       <li key={post.id}>
    //         <p>{post.message}</p>
    //         <p><small>{new Date(post.created_time).toLocaleString()}</small></p>
    //       </li>
    //     ))}
    //   </ul>
    // </div>
  );
};

export default FacebookPosts;



