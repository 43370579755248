import Slider from "react-slick";
import React, { Component } from "react";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";


function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", }}
        onClick={onClick}
      />
    );
  };


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "red" }}
        onClick={onClick}
      />
    );
  }


const TeamMember = () => {

    const {t} = useTranslation();


    const settings = {
            
        className: "center",
        dots: true,
        centerMode: true,
        infinite: true,
        centerPadding: "0px",
        slidesToShow: 3,
        initialSlide: 0,
        speed: 3000,
        autoplaySpeed: 5000,
        cssEase: "linear",
        autoplay: true,
      //   nextArrow: <SampleNextArrow />,
      //   prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };


      return (
        <>
        {/* <!-- Team Member Area --> */}
    <div id="tm-area-team" className="tm-team-area tm-section tm-padding-section bg-grey">
        <div className="tm-team-areabgshape">
            <img src="assets/images/team-area-bgshape.png" alt="appmyil bg shape"/>
        </div>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="tm-sectiontitle text-center">
                        <h2>{t('section_Team_title')}</h2>
                        <span className="tm-sectiontitle-divider"><i className="zmdi zmdi-fullscreen"></i></span>
                    </div>
                </div>
            </div>
            <div className="">

                {/* <!-- Team Member --> */}
               <Slider {...settings}>
                <div className="col-lg-3 col-md-6 col-12">
                        <div className="tm-team text-center">
                            <div className="tm-team-top">
                                <div className="tm-team-image">
                                    <div className="tm-team-imageinner">
                                        <img src="assets/images/AmphiMill COO(responsable des opperation).jpg" alt="appmyil team member"/>
                                    </div>
                                </div>
                                <button className="tm-team-socialtrigger"><i className="zmdi zmdi-share"></i></button>
                                <ul className="tm-team-socialicons">
                                    <li><a href="#"><i className="zmdi zmdi-facebook"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-twitter"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-pinterest"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-dribbble"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-behance"></i></a></li>
                                </ul>
                            </div>
                            <div className="tm-team-content text-center">
                                <h6>{t('section_Team_coo')}</h6>
                                <h4>Raye Mounchili</h4>
                            </div>
                        </div>
                    </div>
                    {/* <!--// Team Member -->

                    <!-- Team Member --> */}
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="tm-team text-center">
                            <div className="tm-team-top">
                                <div className="tm-team-image">
                                    <div className="tm-team-imageinner">
                                        <img src="assets/images/AmphiMill Developer & manager oldrich.jpg" alt="appmyil team member"/>
                                    </div>
                                </div>
                                <button className="tm-team-socialtrigger"><i className="zmdi zmdi-share"></i></button>
                                <ul className="tm-team-socialicons">
                                    <li><a href="#"><i className="zmdi zmdi-facebook"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-twitter"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-pinterest"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-dribbble"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-behance"></i></a></li>
                                </ul>
                            </div>
                            <div className="tm-team-content">
                                <h6>{t('section_Team_cto')}</h6>
                                <h4>Ngnitedem Oldrich</h4>
                            </div>
                        </div>
                    </div>
                    {/* <!--// Team Member -->

                    <!-- Team Member --> */}
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="tm-team text-center">
                            <div className="tm-team-top">
                                <div className="tm-team-image">
                                    <div className="tm-team-imageinner">
                                        <img src="assets/images/AmphiMill Developer & manager marlin.jpg" alt="appmyil team member"/>
                                    </div>
                                </div>
                                <button className="tm-team-socialtrigger"><i className="zmdi zmdi-share"></i></button>
                                <ul className="tm-team-socialicons">
                                    <li><a href="#"><i className="zmdi zmdi-facebook"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-twitter"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-pinterest"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-dribbble"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-behance"></i></a></li>
                                </ul>
                            </div>
                            <div className="tm-team-content">
                                <h6>{t('section_Team_cio')}</h6>
                                <h4>Kougaba Marlin</h4>
                            </div>
                        </div>
                    </div>
                    {/* <!--// Team Member -->

                    <!-- Team Member --> */}
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="tm-team text-center">
                            <div className="tm-team-top">
                                <div className="tm-team-image">
                                    <div className="tm-team-imageinner">
                                        <img src="assets/images/AmphiMill Developer & manager yvan.jpg" alt="appmyil team member"/>
                                    </div>
                                </div>
                                <button className="tm-team-socialtrigger"><i className="zmdi zmdi-share"></i></button>
                                <ul className="tm-team-socialicons">
                                    <li><a href="#"><i className="zmdi zmdi-facebook"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-twitter"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-pinterest"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-dribbble"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-behance"></i></a></li>
                                </ul>
                            </div>
                            <div className="tm-team-content">
                                <h6>{t('section_Team_cpo')}</h6>
                                <h4>Djila Yvan</h4>
                            </div>
                        </div>
                    </div>
                    {/* <!--// Team Member -->

                    <!-- Team Member --> */}
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="tm-team text-center">
                            <div className="tm-team-top">
                                <div className="tm-team-image">
                                    <div className="tm-team-imageinner">
                                        <img src="assets/images/author-image-2.jpg" alt="appmyil team member"/>
                                    </div>
                                </div>
                                <button className="tm-team-socialtrigger"><i className="zmdi zmdi-share"></i></button>
                                <ul className="tm-team-socialicons">
                                    <li><a href="#"><i className="zmdi zmdi-facebook"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-twitter"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-pinterest"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-dribbble"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-behance"></i></a></li>
                                </ul>
                            </div>
                            <div className="tm-team-content">
                                <h6>{t('section_Team_amb')}</h6>
                                <h4>Terry Martin</h4>
                            </div>
                        </div>
                    </div>
                    {/* <!--// Team Member --> */}   

                {/* <!-- Team Member --> */} 
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="tm-team text-center">
                            <div className="tm-team-top">
                                <div className="tm-team-image">
                                    <div className="tm-team-imageinner">
                                        <img src="assets/images/kemgne.jpg" alt="appmyil team member" height="225"width="217" />
                                    </div>
                                </div>
                                <button className="tm-team-socialtrigger"><i className="zmdi zmdi-share"></i></button>
                                <ul className="tm-team-socialicons">
                                    <li><a href="#"><i className="zmdi zmdi-facebook"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-twitter"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-pinterest"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-dribbble"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-behance"></i></a></li>
                                </ul>
                            </div>
                            <div className="tm-team-content">
                                <h6>{t('section_Team_cco')}</h6>
                                <h4>Defo Florane</h4>
                            </div>
                        </div>
                    </div>
                    {/* {/* <!--// Team Member -->}    */}

                     {/* <!-- Team Member --> */} 
                     <div className="col-lg-3 col-md-6 col-12">
                        <div className="tm-team text-center">
                            <div className="tm-team-top">
                                <div className="tm-team-image">
                                    <div className="tm-team-imageinner">
                                        <img src="assets/images/denio.jpg" alt="appmyil team member" height="225"width="217"/>
                                    </div>
                                </div>
                                <button className="tm-team-socialtrigger"><i className="zmdi zmdi-share"></i></button>
                                <ul className="tm-team-socialicons">
                                    <li><a href="#"><i className="zmdi zmdi-facebook"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-twitter"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-pinterest"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-dribbble"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-behance"></i></a></li>
                                </ul>
                            </div>
                            <div className="tm-team-content">
                                <h6>{t('section_Team_cdo')}</h6>
                                <h4>Jiomo  Aimee</h4>
                            </div>
                        </div>
                    </div>
                    {/* {/* <!--// Team Member -->}    */}
               </Slider>
               <div className="row justify-content-center tm-button-size">
                    <a href="#tm-area-contact" className="tm-button "><span>{t('section_Team_joinUs')}</span></a>  
                </div>
            </div>
        </div>
    </div>
    
    
    {/* <!--// Team Member Area --> */}
</>
      )
}


export default TeamMember