import React from 'react';
import { useTranslation } from "react-i18next";
import i18next from "i18next";



function FunfactArea () {

    const {t} = useTranslation();


    return (
        <>
                {/* <!-- Video & Funfact Area --> */}
            <div id="tm-area-video" className="tm-video-funfact-area tm-section tm-padding-section bg-grey">
                <div className="tm-video-funfact-bgshape">
                    <img src="assets/images/funfact-bg-shape.png" alt="bg shape"/>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="tm-videobox tm-videobox-circle tm-funfact-videobox">
                                <img src="assets/images/video-thumb-image-1.jpg" alt="appmyil video image"/>
                                <a href="https://www.youtube.com/watch?v=fFZHhZazXK4" data-fancybox="video"><i className="flaticon-play-button"></i></a>
                                <span className="tm-videobox-roundicon"></span>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row tm-funfact-wrapper">

                                {/* <!-- Single Funfact --> */}
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 tm-funfact-masonryitem">
                                    <div className="tm-funfact text-center">
                                        <span className="tm-funfact-icon">
                                            <i className="flaticon-inbox"></i>
                                        </span>
                                        <div className="tm-funfact-content">
                                            <span className="tm-funfact-number"><span className="odometer" data-count-to="100"></span>5K+</span>
                                            <h4>{t('section_funfact1')}</h4>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--// Single Funfact --> */}

                                {/* <!-- Single Funfact --> */}
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 tm-funfact-masonryitem">
                                    <div className="tm-funfact text-center">
                                        <span className="tm-funfact-icon">
                                            <i className="flaticon-goal"></i>
                                        </span>
                                        <div className="tm-funfact-content">
                                            <span className="tm-funfact-number"><span className="odometer" data-count-to="1000"></span>2K+</span>
                                            <h4>{t('section_funfact2')}</h4>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--// Single Funfact --> */}

                                {/* <!-- Single Funfact --> */}
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 tm-funfact-masonryitem">
                                    <div className="tm-funfact text-center">
                                        <span className="tm-funfact-icon">
                                            <i className="flaticon-team-1"></i>
                                        </span>
                                        <div className="tm-funfact-content">
                                            <span className="tm-funfact-number"><span className="odometer" data-count-to="50"></span>120K+</span>
                                            <h4>{t('section_funfact3')}</h4>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--// Single Funfact --> */}

                                {/* <!-- Single Funfact --> */}
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 tm-funfact-masonryitem">
                                    <div className="tm-funfact text-center">
                                        <span className="tm-funfact-icon">
                                            <i className="flaticon-review"></i>
                                        </span>
                                        <div className="tm-funfact-content">
                                            <span className="tm-funfact-number"><span className="odometer" data-count-to="10"></span>1K+</span>
                                            <h4>{t('section_funfact4')}</h4>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--// Single Funfact --> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--// Video & Funfact Area --> */}
        </>
    )}

export default FunfactArea