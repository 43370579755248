import Slider from "react-slick";
import React, { Component } from "react";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";


const ScreenshotArea = ( ) => {

    const {t} = useTranslation();

    const settings = {
            
                  className: "center",
                  dots: true,
                  centerMode: true,
                  infinite: true,
                  centerPadding: "50px",
                  slidesToShow: 3,
                  speed: 500,
                  initialSlide: 0,
                  autoplaySpeed: 2000,
                  cssEase: "linear",
                  autoplay: true,
                //   nextArrow: <SampleNextArrow />,
                //   prevArrow: <SamplePrevArrow />,
                  responsive: [
                    {
                      breakpoint: 1024,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                      }
                    },
                    {
                      breakpoint: 600,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                      }
                    },
                    {
                      breakpoint: 480,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                      }
                    }
                  ]
                };

    return(
        <>
          <div id="tm-area-screenshots" className="tm-screenshot-area tm-section tm-padding-section bg-white">
              <div className="tm-screenshots-bgshape">
                  <img src="assets/images/screenshot-background-shape.png" alt="screenshot background shape"/>
              </div>
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-lg-8">
                          <div className="tm-sectiontitle text-center">
                              <h2>{t('section_screenshot_title')}</h2>
                              <span className="tm-sectiontitle-divider"><i className="zmdi zmdi-fullscreen"></i></span>
                          </div>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-12">
                          <div className="tm-screenshots">
                              <div className="tm-screenshots-slider tm-slider-arrow">
                                  <Slider {...settings}>
                                  <div className="p-r-30">
                                      <img src="assets/images/Screenshot1.png" alt="appmyil screenshot"/>
                                  </div>
                                  <div className="p-r-30">
                                      <img src="assets/images/Screenshot2.png" alt="appmyil screenshot"/>
                                  </div>
                                  <div className="p-r-30">
                                      <img src="assets/images/Screenshot3.png" alt="appmyil screenshot"/>
                                  </div>
                                  <div className="p-r-30">
                                      <img src="assets/images/Screenshot4.png" alt="appmyil screenshot"/>
                                  </div>
                                  <div className="p-r-30">
                                      <img src="assets/images/Screenshot5.png" alt="appmyil screenshot"/>
                                  </div>
                                  <div className="p-r-30">
                                      <img src="assets/images/Screenshot6.png" alt="appmyil screenshot"/>
                                  </div>
                                  <div className="p-r-30">
                                      <img src="assets/images/Screenshot7.png" alt="appmyil screenshot"/>
                                  </div>
                                  <div className="p-r-30">
                                      <img src="assets/images/Screenshot8.png" alt="appmyil screenshot"/>
                                  </div>
                                  <div className="p-r-30">
                                      <img src="assets/images/Screenshot9.png" alt="appmyil screenshot"/>
                                  </div>
                                  <div className="p-r-30">
                                      <img src="assets/images/Screenshot10.png" alt="appmyil screenshot"/>
                                  </div>
                                  </Slider>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
                       
      </>
    )
}



export default ScreenshotArea