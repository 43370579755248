import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function FaqArea() {
  const { t } = useTranslation();

  return (
    <>
      {/* <!-- Frequently Ask Question --> */}
      <div
        id="tm-area-faq"
        className="tm-faq-area tm-section tm-padding-section bg-white"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="tm-sectiontitle text-center">
                <h2>{t("section_Faq_title")}</h2>
                <span className="tm-sectiontitle-divider">
                  <i className="zmdi zmdi-fullscreen"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="tm-faq-image">
                <img src="assets/images/faq-image.png" alt="faq image" />
              </div>
            </div>
            <div className="col-lg-7">
              {/* <!-- Accordion Wrapper --> */}
              <div id="tm-accordion1" className="tm-accordion">
                <div className="card">
                  <div className="card-header" id="tm-accordion1-heading1">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#tm-accordion1-collapse1"
                        aria-expanded="true"
                        aria-controls="tm-accordion1-collapse1"
                      >
                        {t("section_Faq_firtquest_title")}
                      </button>
                    </h5>
                  </div>
                  <div
                    id="tm-accordion1-collapse1"
                    className="collapse show"
                    aria-labelledby="tm-accordion1-heading1"
                    data-parent="#tm-accordion1"
                  >
                    <div className="card-body">
                      <p>{t('section_Faq_firtquest_desc')}</p>
                      {/* <p>Télécharger l'application Amphi Mill sous .</p>
                      <ul>
                        <li>
                        <i className="zmdi zmdi-android"></i>
                          <a href="https://play.google.com/store/apps/details?id=com.amphimill.app">
                             <span>PlayStore</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://apps.apple.com/fr/app/amphi-mill/id6466167435">
                          <i className="zmdi zmdi-apple"></i> <span>AppStore</span>  
                          </a>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="tm-accordion1-heading2">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#tm-accordion1-collapse2"
                        aria-expanded="false"
                        aria-controls="tm-accordion1-collapse2"
                      >
                        {t("section_Faq_secondquest_title")}
                      </button>
                    </h5>
                  </div>
                  <div
                    id="tm-accordion1-collapse2"
                    className="collapse"
                    aria-labelledby="tm-accordion1-heading2"
                    data-parent="#tm-accordion1"
                  >
                    <div className="card-body">
                      <p>
                      {t('section_Faq_secondquest_desc')}
                      </p>
                      {/* <ul>
                        <li>
                          Libero exercitationem omnis blanditiis ea quo et rem.
                        </li>
                        <li>
                          Beatae veritatis aliquid vel quisquam saepe incidunt.
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="tm-accordion1-heading3">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#tm-accordion1-collapse3"
                        aria-expanded="false"
                        aria-controls="tm-accordion1-collapse3"
                      >
                        {t("section_Faq_thirtquest_title")}
                      </button>
                    </h5>
                  </div>
                  <div
                    id="tm-accordion1-collapse3"
                    className="collapse"
                    aria-labelledby="tm-accordion1-heading3"
                    data-parent="#tm-accordion1"
                  >
                    <div className="card-body">
                      <p>
                      {t('section_Faq_thirtquest_desc')}
                       
                      </p>
                    </div>
                  </div>
                </div>

                {/* <div className="card">
                  <div className="card-header" id="tm-accordion1-heading4">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#tm-accordion1-collapse4"
                        aria-expanded="false"
                        aria-controls="tm-accordion1-collapse4"
                      >
                        {t("section_Faq_fourquest_title")}
                      </button>
                    </h5>
                  </div>
                  <div
                    id="tm-accordion1-collapse4"
                    className="collapse"
                    aria-labelledby="tm-accordion1-heading4"
                    data-parent="#tm-accordion1"
                  >
                    <div className="card-body">
                      <p>
                        An App is a term to describe a web-based program. These
                        web-based or mobile applications perform a specific task
                        for the user. website where one can download these small
                        programs for use on mobile devices such as a Smart
                        phone.
                      </p>
                    </div>
                  </div>
                </div> */}

                <div className="card">
                  <div className="card-header" id="tm-accordion1-heading5">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#tm-accordion1-collapse5"
                        aria-expanded="false"
                        aria-controls="tm-accordion1-collapse5"
                      >
                        {t("section_Faq_fivequest_title")}
                      </button>
                    </h5>
                  </div>
                  <div
                    id="tm-accordion1-collapse5"
                    className="collapse"
                    aria-labelledby="tm-accordion1-heading5"
                    data-parent="#tm-accordion1"
                  >
                    <div className="card-body">
                      <p>
                      {t('section_Faq_fivequest_desc')}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="tm-accordion1-heading6">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#tm-accordion1-collapse6"
                        aria-expanded="false"
                        aria-controls="tm-accordion1-collapse6"
                      >
                        {t("section_Faq_sixquest_title")}
                      </button>
                    </h5>
                  </div>
                  <div
                    id="tm-accordion1-collapse6"
                    className="collapse"
                    aria-labelledby="tm-accordion1-heading6"
                    data-parent="#tm-accordion1"
                  >
                    <div className="card-body">
                      <p>
                      {t("section_Faq_sixquest_desc")}
                        {/* An App is a term to describe a web-based program. These
                        web-based or mobile applications perform a specific task
                        for the user. website where one can download these small
                        programs for use on mobile devices such as a Smart
                        phone. */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--// Accordion Wrapper --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!--// Frequently Ask Question --> */}
    </>
  );
}

export default FaqArea;
