import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { toast } from 'react-toastify';
import Loader from './Loader';


function ContactArea () {
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({ date: new Date() });
    const formData = new FormData();
    const [type, setType] = useState('');
    const [title, setTitle] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const {t} = useTranslation();
    const url = "https://api.amphimill.com/api/v1/suggestion";
    const types = [
        { label: 'SUGGESSTION'},
        // { label: 'MOTIVATION'},
        // { label: 'AVERTISSEMENT'},
        { label: 'OTHER'},
    ];

    const subjects = [
        { label: 'Feedback'},
        { label: 'Support'},
        // { label: 'Motivation'},
        // { label: 'House'},
        { label: 'Other'},
    ];
   

    // useEffect(() => {
    //     fetch(url)
    //     .then(response => response.json()).then(json => {
           
    //         toast.success('voici la reponse de api');
    //     }).catch(e => {
    //         console.log("voici l'erreur lors de l'appel API");
    //     })
    // }, []);


    const handleSubmit = (event)  => {
        event.preventDefault();
        const suggession = { subject, type, title , email, phoneNumber, message };
        for (const key in suggession) {
            formData.append(key, suggession[key]);
        }
        console.log("frommmdaaattaaaa", formData);
        addSuggestionData(formData);
        //  setLoading(false)
    };

    const addSuggestionData = async (payload) => {
        console.log('paylod', payload);
        setIsLoading(true);
        await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
            },
            body: payload
        })
        .then(response => {
            console.log("voici la reponse de l'api", response);
            console.log("voici la reponse de l'api", response.json());
            if(response.state == 200){
                toast('succès');
                setIsLoading(false);
            }
            
        }).catch(e => {
            setIsLoading(false);
            console.log("voici l'erreur lors de l'appel API");
        });
    }

    // const handleChange = (event, ) => {
    //     event.persist();
    //     console.log('first letter', event.target.value, event.target.name);
    //     setState({ ...state, [event.target.name]: event.target.value });
    //     console.log('this is stateset', state);
    // };
    const s = {
        t1 : "test",
        t2 : "fjk",
        t3: "5665",
    } ;
    return (
        <>
                {/* <!-- Contact Area --> */}
            <div id="tm-area-contact" className="tm-contact-area tm-section tm-padding-section bg-white">
                <div className="tm-contact-bgshape">
                    <img src="assets/images/bg-shape-contact.png" alt="contact shape"/>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="tm-sectiontitle text-center">
                            <h2>{t('section_Contact_title')}</h2>
                            <span className="tm-sectiontitle-divider"><i className="zmdi zmdi-fullscreen"></i></span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            <form id="tm-contactform" onSubmit={handleSubmit} className="tm-form tm-contact-form">
                                <div className="tm-form-inner">
                                    <div className="tm-form-field tm-form-fieldhalf">
                                        <input type="text" value={title} placeholder="Full Name" required="required" onChange={(e) => setTitle(e.target.value)}/>
                                        <span className="tm-form-animatedline"></span>
                                    </div>
                                    <div className="tm-form-field tm-form-fieldhalf">
                                        
                                        <select value={type} onChange={(e)=> setType(e.target.value)} required>
                                        <option value="" disabled>Select Type</option>
                                            {types.map(item => {
                                                return <option value={item.label}>{item.label}</option>
                                            })}
                                            
                                        </select>
                                        <span className="tm-form-animatedline"></span>
                                    </div>
                                    <div className="tm-form-field tm-form-fieldhalf">
                                        
                                        <select value={subject} onChange={(e)=> setSubject(e.target.value)} required>
                                        <option value="" disabled>Select Subject</option>
                                            {subjects.map(item => {
                                                return <option value={item.label}>{item.label}</option>
                                            })}
                                            
                                        </select>
                                        <span className="tm-form-animatedline"></span>
                                    </div>
                                    <div className="tm-form-field tm-form-fieldhalf">
                                        <input type="email" value={email} placeholder="Email Address" required="required" onChange={(e)=> setEmail(e.target.value)}/>
                                        <span className="tm-form-animatedline"></span>
                                    </div>
                                    {/* <div className="tm-form-field tm-form-fieldhalf">
                                        <input type="text" name="subject" placeholder="Subject" required="required"/>
                                        <span className="tm-form-animatedline"></span>
                                    </div> */}
                                    <div className="tm-form-field tm-form-fieldhalf">
                                        <input value={phoneNumber} type="text" placeholder="Phone" required="required" onChange={(e)=> setPhoneNumber(e.target.value)}/>
                                        <span className="tm-form-animatedline"></span>
                                    </div>
                                    <div className="tm-form-field">
                                        <textarea value={message} cols="30" rows="4" placeholder="Message" required="required" onChange={(e)=> setMessage(e.target.value)} ></textarea>
                                        <span className="tm-form-animatedline"></span>
                                    </div>
                                    
                                    <div className="tm-form-field">
                                        {
                                            isLoading ? <Loader /> : 
                                            <button type="submit" className="tm-button"><span>{t('section_Contact_button')}</span></button>
                                        }  
                                    </div>
                                    
                                </div>
                            </form>
                            <p className="form-messages"></p>
                        </div>
                        <div className="col-lg-4">
                            <div className="tm-contact-content">
                                <div className="tm-contact-block">
                                    <span className="tm-contact-block-icon">
                                        <i className="zmdi zmdi-pin"></i>
                                    </span>
                                    <div className="tm-contact-block-content">
                                        <h6>{t('section_Contact_adress')}</h6>
                                        <p>Total Melen, Yaoundé, Cameroun</p>
                                    </div>
                                </div>
                                <div className="tm-contact-block">
                                    <span className="tm-contact-block-icon">
                                        <i className="zmdi zmdi-phone-in-talk"></i>
                                    </span>
                                    <div className="tm-contact-block-content">
                                        <h6>{t('section_Contact_phone')}</h6>
                                        <p><a href="tel:+237692033342">(+237) - 692 03 33 42</a></p>
                                        <p><a href="tel:+237690440645">(+237) - 659 47 63 35</a></p>
                                        <p><a href="tel:+237695926008<">(+237) - 651 03 74 24</a></p>
                                    </div>
                                </div>
                                <div className="tm-contact-block">
                                    <span className="tm-contact-block-icon">
                                        <i className="zmdi zmdi-email"></i>
                                    </span>
                                    <div className="tm-contact-block-content">
                                        <h6>{t('section_Contact_email')}</h6>
                                        <p><a href="contact@amphimill.com">contact@amphimill.com </a></p>
                                        <p><a href="mailto:contact@amphimill.com">amphimill@gmail.com</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--// Contact Area -->

            <!-- Contact Form --> */}
            <div className="google-map-area bg-white">
                <div id="google-map" className="tm-google-map"></div>
            </div>
            {/* <!--// Contact Form --> */}
        </>
    )}
export default ContactArea