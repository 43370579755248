import React from 'react';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
// import logo from '../assets/images/logo-white.png';


function ABoutUs () {

    const {t} = useTranslation();

    return (
        <>
                {/* <!-- About Us Area --> */}
            <div id="tm-area-about" className="tm-about-area tm-section tm-padding-section bg-grey">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="tm-sectiontitle text-center">
                                <h2>{t('section_about')}</h2>
                                <span className="tm-sectiontitle-divider"><i className="zmdi zmdi-fullscreen"></i></span>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="tm-about-image">
                                <img className="tm-about-mobilethumb" src="assets/images/about-mobile.png" alt="about mobile" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="tm-about-content">
                                <h4>{t('section_about_title')}</h4>
                                <h6>{t('section_about_subTitle')}</h6>
                                <p>{t('section_about_des')}<br/>{t('section_about_des2')}</p>
                                <ul className="stylish-list">
                                    <li><i className="zmdi zmdi-check"></i> {t('section_about_list1')}</li>
                                    <li><i className="zmdi zmdi-check"></i> {t('section_about_list2')}</li>
                                    <li><i className="zmdi zmdi-check"></i> {t('section_about_list3')}</li>
                                    <li><i className="zmdi zmdi-check"></i> {t('section_about_list4')}</li>
                                    <li><i className="zmdi zmdi-check"></i> {t('section_about_list5')}</li>
                                </ul>
                                <a href="#" className="tm-button"><span>{t('section_button')}</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--// About Us Area --> */}
        </>
    )
}

export default ABoutUs