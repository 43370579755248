import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Loader from '../components/Loader';

const DeepPage = () => {

    const templateurl = "https://link.amphimill.com/deep?type=letypedeparage&code=lecodedepartage";
    
    const location = useLocation();

    const idappsto = "app/amphi-mill/id6466167435"

    
    useEffect(() => {
        
         var queryString = location.search; // Get query string from URL
         
         var userAgent = navigator.userAgent || navigator.vendor || window.opera;

         

         //var baseAppUrl = 'com.amphimill.app://';

         const baseAppUrl = 'https://link.amphimill.com/';

         
         const appUrlWithParams = `${baseAppUrl}deep?${queryString.substr(1)}`;// Remove '?' from query string
         
         console.log("user location", `intent://deep${queryString}#Intent;scheme=https;package=com.amphimill.app;end`);

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            window.location.href = appUrlWithParams; // Use the URL with parameters for deep linking
            setTimeout(() => {
               //window.location.href = 'https://apps.apple.com/app/R5VUVQCFZ5.com.amphimill.app';
               window.location.href = `https://apps.apple.com/app/id6466167435`;
                
            }, 2000);
        } else if (/android/i.test(userAgent)) {
            //window.location.href = appUrlWithParams; // Use the URL with parameters for deep linking
            // window.location.href = `intent://deep?${queryString.substr(1)}#Intent;scheme=https;package=com.amphimill.app;end`;
           // window.location.href = `intent://deep${queryString}#Intent;scheme=https;package=com.amphimill.app;end`;
            window.location.href = `intent://link.amphimill.com/deep${queryString}#Intent;scheme=https;package=com.amphimill.app;end`;
            setTimeout(() => {
                window.location.href = 'https://play.google.com/store/apps/details?id=com.amphimill.app';
            }, 2000);
        } 
        else {
            window.location.href = 'https://www.amphimill.com';
            
        }
    }, [location.search]); // React to changes in the search string

    return (
        <>
             <div className="d-flex justify-content-center align-items-center vh-100">
                <span>Loader</span>
                <Loader/>
              
            </div>
        </>
    );
}

export default DeepPage;