import React from 'react';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
// import logo from '../assets/images/logo-white.png';


function SingleFeature () {

    const {t} = useTranslation();

    return (
        <>
            <div id="tm-area-features" className="tm-features-area tm-section tm-padding-section bg-white">
                <div className="container">
                    <div className="row justify-content-center mt-30-reverse">

                        {/* <!-- Single Features --> */}
                        <div className="col-lg-4 col-md-6 col-12 mt-30">
                            <div className="tm-feature">
                                <span className="tm-feature-icon">
                                    <i className="flaticon-keyword"></i>
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="70px" height="72px">
                                        <path fillRule="evenodd" d="M52.556,54.307 C64.348,37.302 73.747,12.982 68.512,4.877 C60.795,-7.064 13.848,4.659 2.850,20.520 C2.466,21.074 2.124,21.636 1.831,22.199 C-6.854,38.889 17.104,75.968 33.774,71.638 C39.513,70.148 46.364,63.237 52.556,54.307 " />
                                    </svg>
                                </span>
                                <div className="tm-feature-content">
                                    <h4>{t('feature_info')}</h4>
                                    <p>{t('feature_info_desc')}</p>
                                </div>
                            </div>
                        </div>
                        {/* <!--// Single Features -->

                        <!-- Single Features --> */}
                        <div className="col-lg-4 col-md-6 col-12 mt-30">
                            <div className="tm-feature">
                                <span className="tm-feature-icon">
                                    <i className="flaticon-document"></i>
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="70px" height="72px">
                                        <path fillRule="evenodd" d="M52.556,54.307 C64.348,37.302 73.747,12.982 68.512,4.877 C60.795,-7.064 13.848,4.659 2.850,20.520 C2.466,21.074 2.124,21.636 1.831,22.199 C-6.854,38.889 17.104,75.968 33.774,71.638 C39.513,70.148 46.364,63.237 52.556,54.307 " />
                                    </svg>
                                </span>
                                <div className="tm-feature-content">
                                    <h4>{t('feature_oldTopic')}</h4>
                                    <p>{t('feature_oldTopic_desc')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* <!--// Single Features -->

                        <!-- Single Features --> */}
                        <div className="col-lg-4 col-md-6 col-12 mt-30">
                            <div className="tm-feature">
                                <span className="tm-feature-icon">
                                    <i className="flaticon-cover"></i>
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width="70px" height="72px">
                                        <path fillRule="evenodd" d="M52.556,54.307 C64.348,37.302 73.747,12.982 68.512,4.877 C60.795,-7.064 13.848,4.659 2.850,20.520 C2.466,21.074 2.124,21.636 1.831,22.199 C-6.854,38.889 17.104,75.968 33.774,71.638 C39.513,70.148 46.364,63.237 52.556,54.307 " />
                                    </svg>
                                </span>
                                <div className="tm-feature-content">
                                    <h4>{t('feature_procedure')}</h4>
                                    <p>{t('feature_procedure_desc')}</p>
                                </div>
                            </div>
                        </div>
                        {/* <!--// Single Features --> */}

                    </div>
                </div>
            </div>
            {/* <!--// Features Area --> */}
        </>
    )
}

export default SingleFeature