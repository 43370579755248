// import logo from './logo.svg';
//import './assets/css/bootstrap.min.css';
//import './assets/css/_variables.css';
//import './assets/css/custom.css';
//import './assets/css/plugins.css';
//import './assets/css/style.css';
//import './assets/css/style.css.map';
import './App.css';
import Header from './components/Header';
import SingleFeature from './components/Single-feature';
import ABoutUs from './components/About-Us';
import ServiceArea from './components/Service-area';
import FunfactArea from './components/Funfact-area';
import ScreenshotArea from './components/Screenshot-area';
import TeamMember from './components/Team-member';
import TestimonialArea from './components/Testimonial-area';
import FaqArea from './components/Faq-area';
import DownloadArea from './components/Download-area';
import SubscribeArea from './components/Subscribe-area';
import OurLastBlog from './components/OurLast-blog';
import ContactArea from './components/Contact-area';
import FacebookPosts from './components/Facebook-Blog';
import Footer from './components/Footer';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { ToastContainer } from 'react-toastify';
import HomePage from './pages/Home';
import DeepPage from './pages/DeepPage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCg6Vk6dGdULWQJj1aOVN-hGBOg92ikcDc",
  authDomain: "amphimill-b12f6.firebaseapp.com",
  projectId: "amphimill-b12f6",
  storageBucket: "amphimill-b12f6.appspot.com",
  messagingSenderId: "976465879171",
  appId: "1:976465879171:web:2564c1b9964fc8e0067bb6",
  measurementId: "G-XT63RLBKE4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    
    <div>
      <ToastContainer/>
      {/* <div class="tm-preloader">
        <span class="tm-preloader-box"></span>
        <button class="tm-button tm-button-sm tm-button-white"><span>Cancel Loader</span></button>
      </div> */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/deep" element={<DeepPage />} />
        {/* <Route path="/favorites" element={<FavoritesPage />} /> */}
      </Routes>
    </div>
  );
}

export default App;
